/* You can add global styles to this file, and also import other style files */

// Shoepping customize Boostrap

// Custom coloring
$primary: #337ab7;
$info: $primary;

// Font config from MPP
$font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-root: 14px;

$link-color: $primary;

// Navbar styling similar to MPP
$navbar-light-color: $primary;
$navbar-light-hover-color: #23527c;
$navbar-light-active-color: #23527c; //#337ab7;

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// Shoepping global styles

// sortable table header styles
th[phxSortable] {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    position: relative;

    &:not(.text-end) {
        padding-right: 2em;
    }

    &.text-end {
        padding-left: 2em;
    }
}

th[phxSortable]::after,
th[phxSortable]::before {
    font-family: 'bootstrap-icons';
    position: absolute;
    bottom: 0.5em;
    display: block;
}

// left or right aligned
th[phxSortable]:not(.text-end)::after {
    right: 0.5em;
}

th[phxSortable].text-end::before {
    left: 0.5em;
}

th[phxSortable].desc:not(.text-end)::after,
th[phxSortable].desc.text-end::before {
    content: '\F229';
}

th[phxSortable].asc:not(.text-end)::after,
th[phxSortable].asc.text-end::before {
    content: '\F235';
}
/////////////////////////////

.word-wrap {
    overflow-wrap: anywhere;
    hyphens: auto;
}
